import React, { Component } from 'react';

export default class NavBar extends Component {
  render() {
    return <footer class="footer navbar-dark min-vh-15">
    <div class="container">
      <div class="row align-items-center text-almostwhite justify-content-center text-center">
        <div class="col-12 col-md">
          <img class="mb-2" src="/img/logo192i.png" alt="cryptomancy" width="48" height="48" />
          <small class="d-block mb-3 text-muted">&copy; 2021-2022</small>
        </div>
        <div class="col-6 col-md">
          <h5 class="alt-font">opensea</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-muted link-disabled" target="_blank" rel="noreferrer" href="#">
              sigils</a></li>
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://opensea.io/collection/cryptomancy">
              cryptomancy</a></li>
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://opensea.io/collection/blankie">
              blankies</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5 class="alt-font">contracts</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-muted link-disabled" target="_blank" rel="noreferrer" href="#">
              sigils</a></li>
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x7c7fc6d9f2c2e45f12657dab3581ead2bd53bdf1">
              cryptomancy</a></li>
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x0168e2c6cde9c1223867cc9a5eb0833fa3f9f5de">
              blankies</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5 class="alt-font">socials</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://twitter.com/cryptomancynet">
              twitter</a></li>
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://discord.gg/Rq8UQPEYq6">
              discord</a></li>
            <li><a class="link-muted" target="_blank" rel="noreferrer" href="https://t.me/cryptomancy_net">
              telegram</a></li>
          </ul>
        </div>
      </div>
    </div>
    </footer>
  }
}