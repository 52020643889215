import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import './App.css'
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from "./components/scrollToTop";

import Main from './components/Main';
import Home from './pages/Home';
import Sigils from './pages/Sigils';
import Eth from './pages/Eth';
import Blankies from './pages/Blankies';


class App extends Main {
  render() {
    return (
      <Router>
      <ScrollToTop />
      <div class="site-wrapper">
        <NavBar data={this.state} />
        {(this.state.error) && !this.state.loading
          ?<div class="container-fluid"><div class="row justify-content-center align-items-center"><div class="col-lg-5 col-warning">Error connecting - are you on the right network? Try refreshing your browser</div></div></div>
          :''
        }
        <div class="container-fluid">
          <Switch>
            <Route
              path="/eth"
              render={props => <Eth {...props} data={this.state} />}
            />
            <Route
              path="/cards"
              render={props => <Eth {...props} data={this.state} />}
            />
            <Route
              path="/blankies"
              render={props => <Blankies {...props} data={this.state} />}
            />
            <Route
              path="/sigils"
              render={props => <Sigils {...props} data={this.state} />}
            />
            <Route
              path="/" exact
              render={props => <Home {...props} data={this.state} />}
            />
          </Switch>
        </div>
        <Footer data={this.state}/>
      </div>
      </Router>
    );
  }
}

export default App;