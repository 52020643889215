import Main from '../components/Main';

export default class Home extends Main {
  handleSigilClick = (event) => {
    const isTextSelected = window.getSelection().toString();
    if (!isTextSelected) {
      this.props.history.push('/sigils');
    }
  }

  handleBlankieClick = (event) => {
    const isTextSelected = window.getSelection().toString();
    if (!isTextSelected) {
      this.props.history.push('/blankies');
    }
  }

  handleCardClick = (event) => {
    const isTextSelected = window.getSelection().toString();
    if (!isTextSelected) {
      this.props.history.push('/cards');
    }
  }

  render(){
    return <div>
      <div id="stars2"></div>
      <div class="row justify-content-center align-items-center min-vh-85 text-almostwhite">
        <h1 class="alt-font big-title">cryptomancy</h1>
        <div class="col-lg-12 text-center">
          <h3 class="alt-font">experiments in on-chain chaos magick</h3>
          <div class="container">
            <div class="row justify-content-center align-items-center pt-3 d-flex align-items-stretch">
              <div class="col-lg-4 pb-2">
                <div class="card card-light h-100 text-almostwhite p-1" onClick={this.handleSigilClick}>
                  <div class="bb-1"><h4 class="alt-font pt-2"><a href="/sigils" class="text-decoration-none text-almostwhite">sigils</a></h4></div>
                  <div class="card-body flex-card">
                    <p>Fully on-chain sigil generator.</p>
                    <p>Link intention to your wallet and forever hold a digital art representation of your own personal magic.</p>
                    <span class="alt-font"><em>available to mint now</em></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pb-2">
                <div class="card card-light h-100 text-almostwhite p-0" onClick={this.handleCardClick}>
                  <div class=""><h4 class="alt-font pt-2"><a href="/cards" class="text-decoration-none text-almostwhite">cards</a></h4></div>
                  <div class="card-body flex-card">
                    <p>Non-fungible divination.</p>
                    <p>Combining various elements of esoterica to deliver a unique divinatory experience.</p>
                    <span class="alt-font"><em>sold out</em></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pb-2">
                <div class="card card-light h-100 text-almostwhite p-0" onClick={this.handleBlankieClick}>
                  <div class=""><h4 class="alt-font pt-2"><a href="/blankies" class="text-decoration-none text-almostwhite">blankies</a></h4></div>
                  <div class="card-body flex-card">
                    <p>Raw blockchain potential.</p>
                    <p>Breaking the rules of fungibility in a way that embodies the Taoist principle of Pu.</p>
                    <span class="alt-font"><em>available to mint now</em></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}