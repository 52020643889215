import Main from '../components/Main';

import {
  CRYPTOMANCY_CONTRACT_ABI,
  CRYPTOMANCY_CONTRACT_ADDRESS
} from '../config'

import getWeb3 from '../adapters/Web3';

export default class Eth extends Main {

  async loadBlockchainData() {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts().catch((err) => {console.log('error getting account:',err)});
    this.setState({ account: accounts[0] });

    const cryptomancyContract = new web3.eth.Contract(CRYPTOMANCY_CONTRACT_ABI, CRYPTOMANCY_CONTRACT_ADDRESS);
    this.setState({ cryptomancyContract });
    const reserveIds = await cryptomancyContract.methods.getReservations(accounts[0]).call().catch((err) => {console.log('error getting reserveIds:',err)}) || [];
    this.setState({ reserveIds });

    this.setState({ loading: false });
  }

  async mintEth(input, e) {
    // console.log(JSON.stringify(input));
    let inputArray = [];
    for (const property in input) {
      inputArray.push(parseInt(property));
    }
    // console.log(inputArray);
    this.setState({ loading: true })
    e.preventDefault()
    if (inputArray.length < 1) {
      console.log('invalid input provided:', input)
      this.setState({ loading: false })
      return;
    }
    this.state.cryptomancyContract.methods.mintBatchIds(inputArray).send({
      from: this.state.account
    }).then((result) => {
      console.log("Success! Got result:", result);
      this.loadBlockchainData().then((result) => {
        // console.log('here')
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    }).catch((err) => {
      console.log("Failed with error:", err);
      this.loadBlockchainData().then((result) => {
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    });
  }

  onChangeCheck(event){
    console.log(event.target.checked, event.target.name);
    this.state.mintChecked[event.target.name] = event.target.checked;
  };

  constructor(props) {
    super(props);

    this.state = {
      reserveIds: [],
      mintChecked: {},
      cryptomancyContract: {methods: {}}
    }

    this.onChangeCheck=this.onChangeCheck.bind(this);
    this.mintEth = this.mintEth.bind(this);
    this.loadBlockchainData = this.loadBlockchainData.bind(this);
  }

  render(){
    return <div>
      <div id="stars2"></div>
      <div class="row justify-content-center align-items-center min-vh-100" id="words">
        <div class="col-lg-12 text-center"><h1 class="alt-font big-title text-almostwhite">cryptomancy<br />cards</h1></div>
      </div>
      <div class="row card-text text-center text-almostwhite p-1 align-items-center min-vh-100">
        <div class="col-lg-4 front-name text-center">
          <div class="card mx-auto text-almostwhite" style={{padding: '20px'}}>
            <h1 class="card-title alt-font">bridge to ethereum</h1>
            <p class="card-text">Mint ERC-1175 compatible Cryptomancy cards on Ethereum to trade on Opensea.</p>
            <p class="card-text">This is available to anyone who held cards on BSC prior to the snapshot in August 2021. Any changes of ownership after that date are not reflected in the minting contract.</p>
            <p class="card-text">Simply select the checkbox next to each card you wish to migrate and press the mint button</p>
          </div>
        </div>
        <div class="col-lg-8 justify-content-center">
          {this.state.account
            ?<h2 class="alt-font">you have <b>{this.state.reserveIds.length}</b> cards reserved</h2>
            :<h2 class="alt-font">not connected to eth mainnet</h2>
          }
          {/* <hr /> */}
          <p style={{paddingTop: '20px'}}>Available to mint:</p>
          <ul class="column-list list-unstyled">
            { this.state.reserveIds.map((id) => {
              if (id !== '0') {
                return(
                <li><label>
                  <input type="checkbox" name={id} key={id} onChange={this.onChangeCheck}/> Card ID: {id}
                </label></li>
                )
              }
              return false
            }) }
          </ul>
          <div class="row justify-content-center">
            <div class="col-xl-6">
              <button class={!this.state.loading?"btn btn-lg btn-white btn-fill":"btn btn-lg btn-white btn-fill disabled"}
                  href="#" disabled={!this.state.loading?"":"disabled"}
                  onClick={(e) => this.mintEth(this.state.mintChecked, e)}>
                <i class={!this.state.loading?"fas fa-plus fa-fw text-almostwhite text-decoration-none":"fas fa-spinner fa-spin fa-fw text-almostwhite text-decoration-none"}></i> Mint Selected
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-text text-almostwhite align-items-center min-vh-100">
        <div class="col-lg-4 front-name text-center" >
          <img src="/img/0.png" class="border-0 rounded-0" style={{maxHeight: '450px'}}alt="cryptomancy" />
        </div>
        <div class="col-lg-8">
          <div class="card-body d-flex flex-column h-100">
            <p class="card-text mt-auto">The original Cryptomancy project is a series of 1/1 NFTs originally minted on the Binance Smart Chain designed around the concepts of tarot, chaos magic, sigils, and technomancy.</p>
            <p class="card-text">A series of 1471 total hand crafted NFTs have been created, each one totally unique and assembled from a group of magical elements.</p>
            <p class="card-text">Rider Waite tarot cards form the core imagery of the cards, being widely considered one of the most complete presentations of the tarot, and a reflection of the macrocosmos in image form.</p>
            <ul>
              <li><b>Border:</b> Gold borders make up 25% of all cards</li>
              <li><b>Minor Arcana:</b> There are 24 unique iterations of each minor arcana card, of which there are 56</li>
              <li><b>Major Arcana:</b> Only 6 of each major arcana card exists, of which there are 21</li>
              <li><b>The Fool:</b> Only 1 Fool exists, for he exists outside of the major arcana and is a story unto himself. This is the rarest card and has its own unique sigil to go along with it.</li>
              <li><b>Sigils:</b> There are 6 unique sigils spread evenly across all cards (as well as one unique one for the Fool). Each sigil has been magically charged with a different intention, all positive, but all different. Scroll down for further info on the sigils.</li>
              <li><b>Runes:</b> The final element of randomness - 24 runes are spread evenly across the cards, with 6 of these hand picked for their protective and wealth qualities to be applied to the major arcana.</li>
            </ul>
            <p class="card-text">Taken together, the combination of card, sigil, and rune should provide you with a unique story that is a highly personal divination and a deep insight into your own psyche. Pick a card that speaks to you and dive deep within.</p>
          </div>
        </div>
      </div>
      <div class="row card-text text-almostwhite align-items-center min-vh-100">
        <div class="col-lg-4 front-name text-center" >
          <img src="/img/collage.png" class="border-0 rounded-0" style={{maxHeight: '200px'}} alt="sigils" />
        </div>
        <div class="col-lg-8">
          <div class="card-body d-flex flex-column h-100">
            <div class="row justify-content-center text-center">
              <h1 class="alt-font">card sigils</h1>
            </div>
            <p class="card-text mt-auto">Sigils are magical symbols that are crafted to imbue intent into an object or a magical operation. There are many ways they can be created and used, but here they are used in a totally unique way.</p>
            <p class="card-text">I have previously experimented with the use of sigils in NFTs with interesting results. These results usually manifest as coincidence or synchronicity, so can't be directly attributed to any magical operation.</p>
            <p class="card-text">The sigils created for this project have the following embedded intentions:</p>
            <ul>
              <li><b>1:</b> The owner of this NFT wins every trade</li>
              <li><b>2:</b> Airdrops come to those that hold this</li>
              <li><b>3:</b> Success in every endeavour for the holder of this NFT</li>
              <li><b>4:</b> New sources of income continually arise for the current owner of the NFT</li>
              <li><b>5:</b> A life of comfort and luxury awaits you</li>
              <li><b>6:</b> Holding this NFT grants infinite wealth</li>
              <li><b>0:</b> (Unique only to the Fool) Whoever touches this NFT will live a gilded life of opportunity and oppulence</li>
            </ul>
            <p class="card-text">It should go without saying, but for the lowest common denominators out there - magic is an art, not a science, and these intentions aren't guarantees of success or an excuse to be a dumbass. Understand the mechanisms and properties of magic before wondering why it "doesn't work".</p>
          </div>
        </div>
      </div>
      <div class="row card-text text-almostwhite align-items-center min-vh-100">
        <div class="col-lg-4 front-name text-center" >
          <img src="/img/1.png" class="border-0 rounded-0" style={{maxHeight: '450px'}} alt="10 pentacles" />
        </div>
        <div class="col-lg-8">
          <div class="card-body d-flex flex-column h-100">
            <div class="row justify-content-center text-center">
              <h1 class="alt-font">faq</h1>
            </div>
            <p class="card-text mt-auto"><b>What's the deal with ERC-1155 tokens?</b></p>
            <blockquote>Originally the contract for this was an enhanced and modernised version of the Binance Punks (Bunks) contract, which was not ERC-721 compatibile. At the time there were no NFT platforms on Binance Smart Chain, and the competing L2s weren't in the state they are now.</blockquote>
            <blockquote>When we migrated to Eth mainnet through the wrapper contract, we opted to go with ERC-1155 instead of ERC-721 as the standard since the former allows much cheaper gas costs for minting in bulk. Since many holders would be minting dozens of cards as part of the migration, minimising gas costs was our top priority.</blockquote>
            <p class="card-text mt-auto"><b>Why did you manually create them? Are you insane?</b></p>
            <blockquote>Yes, but also for many reasons, most notably to improve on the magic imbued in the sigils. By pouring hours of effort in to seeing and manipulating the images they can be considered more magically charged and be more effective for sigil holders. Another big reason is to introduce imperfection that may influence value beyond the prescribed rarety. There's a big chance I fucked something up when creating these 1471 PNGs, and any with a mistake are hopefully going to be rare enough to have increased value, like how those weird nerds collect postage stamps.</blockquote>
            <p class="card-text mt-auto"><b>How are the cards ordered?</b></p>
            <blockquote>The order of the cards has been completely randomised, even I don't know which numbers are the rare ones, except for a few hand picked magically significant numbers which were very deliberately chosen. Those numbers are a public secret, and any student of the occult would know which numbers to snipe. This randomisation is a core element of the divination experience.</blockquote>
          </div>
        </div>
      </div>
    </div>
  }
}
