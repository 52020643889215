import React from 'react';
import Main from '../components/Main';
import {
  BLANKIES_CONTRACT_ABI,
  BLANKIES_CONTRACT_ADDRESS,
  BLANKIES2_CONTRACT_ABI,
  BLANKIES2_CONTRACT_ADDRESS
} from '../config'
import getWeb3 from '../adapters/Web3';

export default class Blankies extends Main {

  async buyBlankieClick(input, e) {
    this.setState({ blankiesLoading: true })
    this.setState({ loading: true })
    e.preventDefault()
    if (!input.current.value) {
      console.log('invalid input provided:', input.current.value)
      this.setState({ loading: false })
      this.setState({ blankiesLoading: false })
      return;
    }
    let buyValue = this.state.blankiesPrice * input.current.value;
    //let buyValue = Web3.utils.toWei(input.current.value, 'ether')
    this.state.blankiesContract.methods.mintMany(input.current.value).send({
      from: this.state.account,
      value: buyValue
    }).then((result) => {
      console.log("Success! Got result:", result);
    }).catch((err) => {
      console.log("Failed with error:", err);
    }).finally(
      this.loadBlockchainData().then((result) => {
        this.setState({ blankiesLoading: false })
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ blankiesLoading: false })
        this.setState({ loading: false })
      })
    );
  }

  async buyBlankie2Click(input, e) {
    this.setState({ blankies2Loading: true })
    this.setState({ loading: true })
    e.preventDefault()
    if (!input.current.value) {
      console.log('invalid input provided:', input.current.value)
      this.setState({ loading: false })
      this.setState({ blankies2Loading: false })
      return;
    }
    let buyValue = this.state.blankies2Price * input.current.value;
    this.state.blankies2Contract.methods.mintMany(input.current.value).send({
      from: this.state.account,
      value: buyValue
    }).then((result) => {
      console.log("Success! Got result:", result);
    }).catch((err) => {
      console.log("Failed with error:", err);
    }).finally(
      this.loadBlockchainData().then((result) => {
        this.setState({ blankies2Loading: false })
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ blankies2Loading: false })
        this.setState({ loading: false })
      })
    );
  }

  async loadBlockchainData() {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts().catch((err) => {console.log('error getting account:',err)});
    this.setState({ account: accounts[0] });

    const blankiesContract = new web3.eth.Contract(BLANKIES_CONTRACT_ABI, BLANKIES_CONTRACT_ADDRESS)
    this.setState({ blankiesContract })
    const blankiesSupply = await blankiesContract.methods.maxSupply().call().catch((err) => {console.log('error getting blankiesSupply:',err)})
    this.setState({ blankiesSupply })
    const blankiesMinted = await blankiesContract.methods.getCurrent().call().catch((err) => {console.log('error getting blankiesMinted:',err)})
    this.setState({ blankiesMinted })
    const blankiesPrice = await blankiesContract.methods.claimPrice().call().catch((err) => {console.log('error getting blankiesPrice:',err)})
    this.setState({ blankiesPrice })

    const blankies2Contract = new web3.eth.Contract(BLANKIES2_CONTRACT_ABI, BLANKIES2_CONTRACT_ADDRESS)
    this.setState({ blankies2Contract })
    const blankies2Supply = await blankies2Contract.methods.maxSupply().call().catch((err) => {console.log('error getting blankies2Supply:',err)})
    this.setState({ blankies2Supply })
    const blankies2Minted = await blankies2Contract.methods.getCurrent().call().catch((err) => {console.log('error getting blankies2Minted:',err)})
    this.setState({ blankies2Minted })
    const blankies2Price = await blankies2Contract.methods.claimPrice().call().catch((err) => {console.log('error getting blankies2Price:',err)})
    this.setState({ blankies2Price })

    this.setState({ loading: false });
  }

  constructor(props) {
    super(props);

    this.state = {
      blankiesSupply: 128,
      blankies2Supply: 3333,
      blankiesMinted: 0,
      blankies2Minted: 0,
      blankiesPrice: 0,
      blankies2Price: 0,
      blankiesContract: {methods: {}},
      blankies2Contract: {methods: {}},
      blankiesLoading: false,
      blankies2Loading: false,
    }

    this.buyBlankieClick = this.buyBlankieClick.bind(this);
    this.buyBlankie2Click = this.buyBlankie2Click.bind(this);
    this.mintBlankiesAmount = React.createRef();
    this.mintBlankies2Amount = React.createRef();
    this.loadBlockchainData = this.loadBlockchainData.bind(this);
  }

  render(){
    return <div>
      <div id="stars2"></div>
      <div class="row justify-content-center align-items-center min-vh-100" id="words">
        <div class="col-lg-12 text-center"><h1 class="alt-font big-title text-almostwhite">blankies</h1></div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100">
        <div class="container">
          <div class="row card-text text-center text-almostwhite p-2">
            <div class="col-lg-4 front-name">
              <h1 class="alt-font">blankies</h1>
            </div>
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body d-flex flex-column h-100 alt-font">
                  <span>mint a fungible blankie for 0.015<i class="fab fa-ethereum"></i></span>
                  <div class="spacer"></div>
                    <div class="input-group mb-3 btn-white">
                      <div class="input-group-prepend">
                        <button class="btn btn-sm btn-ig btn-fill">amount</button>
                      </div>
                      <input type="text" class="input-white form-control" ref={this.mintBlankies2Amount}/>
                    </div>
                    <button disabled={!this.state.loading?"":"disabled"}
                      onClick={(e) => this.buyBlankie2Click(this.mintBlankies2Amount, e)}
                      class="btn btn-sm btn-white btn-fill">
                        {this.state.blankies2Loading ?
                          <div><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>loading...</div>
                          : <span>mint</span>}
                    </button>
                    <div class="spacer"></div>
                    <span>{this.state.blankies2Minted} / {this.state.blankies2Supply} minted</span>
                </div>
              </div>
              <div class="spacer"></div>
              <div class="card">
                <div class="card-body d-flex flex-column h-100 alt-font">
                  <span>mint a non-fungible blankie for 3<i class="fab fa-ethereum"></i></span>
                  <div class="spacer"></div>
                    <div class="input-group mb-3 btn-white">
                      <div class="input-group-prepend">
                        <button class="btn btn-sm btn-ig btn-fill">amount</button>
                      </div>
                      <input type="text" class="input-white form-control" ref={this.mintBlankiesAmount}/>
                    </div>
                    <button disabled={!this.state.loading?"":"disabled"}
                      onClick={(e) => this.buyBlankieClick(this.mintBlankiesAmount, e)}
                      class="btn btn-sm btn-white btn-fill">
                        {this.state.blankiesLoading ?
                          <div><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>loading...</div>
                          : <span>mint</span>}
                    </button>
                    <div class="spacer"></div>
                    <span>{this.state.blankiesMinted} / {this.state.blankiesSupply} minted</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100" id="a">
        <div class="col-lg-9">
          <div class="text-almostwhite border-0">
            <div class="row no-gutters">
              <div class="col-lg-4 d-none d-lg-block">
                <img src="/img/blankies-white.png" class="card-img border-0 rounded-0" style={{paddingRight: '50px'}} alt="logo" />
              </div>
              <div class="col-lg-8">
                <div class="card-body d-flex flex-column h-100">
                  <div class="row justify-content-center">
                    <div class="col-xl-12"><h5 class="card-title alt-font">raw blockchain potential</h5></div>
                  </div>
                  <hr/>
                      <p class="card-text mt-auto">Each blankie encapsulates the raw potential of fungible and non-fungible tokens. There is no metadata, no image, just a token id.</p>
                      <p class="card-text"><b>Fungible blankies</b> could be changed at any time by the contract owner.</p>
                      <p class="card-text">The contract could have metadata url added at any point in the future by the contract owner. Each blankie could become any of an infinite number of permutations.</p>
                      <p class="card-text">Could it point to images of unique art? AI generated toilets? Punk knockoffs? Maybe it will stay blank forever.</p>
                      <p class="card-text">There is infinite potential, and <b>there will only ever be 8888</b>.</p>
                      <p class="card-text"><b>Non-fungible blankies</b> are permanently locked in a state of flux.</p>
                      <p class="card-text">Noone can ever set their metadata, their meaning will forever be up to the imagination of their holder. They are star dust.</p>
                      <p class="card-text">These tokens are eternally the uncarved block, and <b>there will only ever be 128</b>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100">
        <div class="container">
          <div class="row justify-content-center align-items-center card-text text-almostwhite p-2">
            <div class="col-lg-8">
              <h1 class="alt-font text-center">blankies</h1>
              <p>Blankies have no metadata. They exist in a state of pure potential.</p>
              <h2 class="alt-font text-center">design</h2>
              <p>There is no design. They are nothing and everything, all at the same time.</p>
              <p>They exist outside of material realm.</p>
              <h2 class="alt-font text-center">contract</h2>
              <p>The contract is the OpenZeppelin ERC-721 template, with minor modifications.</p>
              <h2 class="alt-font text-center">governance</h2>
              <p>There is no governance.</p>
              <h2 class="alt-font text-center">roadmap</h2>
              <p>The roadmap is unknown, infinite, and winding.</p>
              <h2 class="alt-font text-center">team</h2>
              <p>Nothing has been created, so there can be no creators.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
